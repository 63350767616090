.main-screen-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background-color: #fff;
}

.parent-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 70%;
  height: 80vh;
}

.logo-wrapper {
  position: relative;
  z-index: 3;
}

.login-screen-logo {
  margin: 0px auto;
  width: 100%;
}

.child-wrapper {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}

.child {
  width: 50%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.child1-wrapper {
  border-radius: 25px 0px 0px 25px;
  background: linear-gradient(224deg, #00263d 0%, #206f6d 80.73%, #339989 100%);
}

.child2-wrapper {
  background: url("../../assets/images/bg-texture.png");
  height: 100%;
  object-fit: cover;
  background-position: top left;
  background-repeat: no-repeat;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 5px 25px 0px;
  border-radius: 0px 25px 25px 0px;
}

.login-screen-man {
  position: relative;
  height: 80%;
  width: 80%;
  object-fit: contain;
  margin: 0 auto;
}

.main-form-wrapper {
  text-align: center;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 50px;
}

.form-wrapper {
  width: 100%;
}

.page-title {
  font-family: var(--primary-font-montserrat);
  font-size: 1.85rem;
  font-weight: 700;
  line-height: 39px;
}

.forgot-password-description {
  font-family: var(--primary-font-montserrat);
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  text-align: center;
  color: var(--primary-font-color);
}

.email-input-box-wrapper,
.password-input-box-wrapper {
  display: flex;
  position: relative;
}

.emailIcon,
.passwordIcon {
  position: absolute;
  top: 14px;
  left: 15px;
  background-color: transparent;
  height: 22px;
  width: 22px;
}

img.emailIcon {
  height: 20px;
  width: 20px;
  left: 17px;
  top: 15px;
}

.email-input-field,
.password-input-field {
  padding-left: 50px;
  padding-top: 15px;
  padding-bottom: 15px;
  padding-right: 50px;
  text-overflow: ellipsis;
  border: 0.2px solid #aab8c0;
  border-radius: 16px;
  font-family: var(--secondary-font-outfit);
  font-size: 1.1rem;
  font-weight: 400;
  line-height: 19px;
  letter-spacing: 0em;
  text-align: left;
  box-shadow: 0px 4px 4px 0px #0000000d;
}

.forgot-password-link {
  font-family: var(--secondary-font-outfit);
  font-size: 1rem;
  font-weight: 400;
  line-height: 19px;
  letter-spacing: 0em;
  text-decoration: none;
  color: var(--primary-font-color);
  opacity: 0.7;
  padding: 15px 0px;
}

.login-btn {
  font-family: var(--primary-font-montserrat);
  font-size: 15px;
  font-weight: 700;
  line-height: 18px;
  letter-spacing: 0em;
  padding: 15px 25px;
  background-color: var(--green-color);
  color: var(--primary-background-color);
  border: 0;
  border-radius: 16px;
}

.back-to-login-link {
  color: var(--primary-font-color);
  text-decoration: none;
  font-family: var(--secondary-font-outfit);
  font-size: 15px;
  font-weight: 400;
  line-height: 19px;
}
