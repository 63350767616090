.flex-1 {
  flex: 1;
}

.flex-2 {
  flex: 2;
}

.ml-30 {
  margin-left: 30px;
}

.mr-40 {
  margin-right: 40px;
}
.mb-15 {
  margin-bottom: 15px;
}

.width-90 {
  width: 90%;
}

.width-10 {
  width: 10%;
}

.width-15 {
  width: 15%;
}

.width-20 {
  width: 20%;
}

.width-35 {
  width: 35%;
}

.width-65 {
  width: 65%;
}

.width-60 {
  width: 60%;
}

.width-40 {
  width: 40%;
}

.cursor-pointer {
  cursor: pointer;
}

.pl-200 {
  padding-left: 200px;
}

.ml-200 {
  margin-left: 200px;
}
.ml-200.sidebarClose {
  margin-left: 103px;
}

.pl-103 {
  padding-left: 103px;
}

.font-outfit {
  font-family: var(--secondary-font-outfit);
}

.font-montserrat {
  font-family: var(--primary-font-montserrat);
}

.primary-font-color {
  color: var(--primary-font-color) !important;
}

.zIndex-2 {
  z-index: 2;
}

.float-right {
  float: right;
}

.ml-auto {
  margin-left: auto;
}
