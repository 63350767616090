.logsWrapper {
  list-style-type: none;
}

.log::before {
  content: "";
  position: relative;
  display: inline-block;
  left: -15px;
  border: 1px solid var(--primary-font-color);
  height: 9px;
  width: 9px;
  border-radius: 50%;
  top: -1px;
}

.log,
.noLogs {
  border-bottom: 1px solid #ddd;
    padding-bottom: 10px;
    margin-bottom: 10px;
  color: var(--primary-font-color);
  font-family: var(--secondary-font-outfit);
  /* padding: 4px 0px; */
}
