.logo {
    width: 300px;
    object-fit: cover;
  }
  
  .status {
    font-size: 14px;
    padding: 6px 12px;
    border-radius: 8px;
    letter-spacing: 0.5px;
    font-weight: 500;
    user-select: none;
    margin-right: 5px;
  }
  .statusGreen {
    background-color: rgb(111, 242, 111);
  }
  .statusYellow {
    background-color: rgb(246, 246, 80);
  }
  .statusRed {
    color: #fff;
    background-color: rgb(231, 51, 51);
  }
  
  .invoiceTable,
  .invoiceTable table thead th,
  .invoiceTable table tbody td {
    font-family: var(--secondary-font-outfit);
    color: var(--primary-font-color);
  }
  
  .inputBox {
    font-family: var(--secondary-font-outfit);
    font-size: 15px;
    font-weight: 400;
    line-height: 1.25;
    padding: 7px 45px 7px 12px;
    border-radius: 5px;
    color: var(--primary-font-color);
    border: 0.2px solid var(--primary-border-color);
    outline: none;
    text-overflow: ellipsis;
    width: 100%;
  }
  
  .infoText {
    margin: 0;
    position: absolute;
    right: 9px;
    top: 9px;
    font-family: var(--secondary-font-outfit);
    font-size: 15px;
    font-weight: 400;
    line-height: 1;
    color: var(--primary-font-color);
    user-select: none;
  }
  
  .heading {
    font-weight: 600 !important;
    font-family: var(--secondary-font-outfit);
    color: var(--primary-font-color) !important;
  }
  
  .reactQuillEditor {
    width: 100%;
    flex: 1;
  }
  .gridtable {
    width: 100%;
    overflow: hidden;
  }
  
  .gridtable thead {
    position: sticky;
    top: 0; /* Keeps header fixed on scroll */
    background: white; /* Ensure header background is solid */
    z-index: 1; /* Ensure it stays above other content */
  }
  
  .gridtable tbody {
    max-height: 500px;
    overflow-y: auto; /* Use scroll if needed */
    display: block; /* Change to block for overflow */
  }
  
  .gridtable tr {
    display: table; /* Ensures proper row display */
    table-layout: fixed;
    width: 100%; /* Ensures rows take full width */
  }
  
  .gridtable td,
  .gridtable th {
    min-width: 100px; /* Set a minimum width for columns */
  }
  
  /* .gridtable tbody{
    display: block;
    max-height: 500px;
    overflow-y: scroll;
  }
  .gridtable thead, .gridtable tbody tr{
    display: table;
    table-layout: fixed;
    width: 100%;
  }
  

  .gridtable thead{
    position: relative;
  } */
