.sidebar {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 215px;
  background: url("../../assets/images/bg-texture.png");
  background-position: top left;
  background-repeat: no-repeat;
  z-index: 4;
  border-right: 1px solid var(--primary-font-color);
  transition: 0.3s ease;
  padding-top: 160px;
}

.sidebar.close {
  width: 103px;
  padding-top: 155px;
}

.sidebar .nav-links {
  height: 100%;
  padding: 0px 0 150px 0;
  overflow: auto;
  position: relative;
}

.toggle-sidebar-btn {
  position: absolute;
  /* right: 5px;
   */
   right: 17px;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
  color: var(--green-color);
  outline: none;
  border: 0;
  transition: all 0.3s ease;
}
.sidebar.close .toggle-sidebar-btn {
  rotate: 180deg;
  top: 10%;
  right: 3px;
}

.subMenu-toggler-btn {
  position: relative;
  rotate: 270deg;
  transition: all 0.3s ease;
  pointer-events: none;
}

.sidebar.close .nav-links {
  overflow: visible;
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: center;
}
.sidebar .nav-links::-webkit-scrollbar {
  display: none;
}
.sidebar .nav-links li {
  position: relative;
  list-style: none;
  transition: all 0.3s ease;
  width: 100%;
}

.sidebar.close .nav-links li {
  padding: 8px 0px;
}

.sidebar.close .nav-links .sub-menu li {
  padding: 0;
}

.sidebar .nav-links li .icon-link {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.sidebar.close .nav-links li .icon-link {
  display: block;
}

span.sidebar-img-wrapper {
  width: 60px;
  padding-left: 15px;
}
.sidebar.close span.sidebar-img-wrapper {
  width: auto;
  padding-left: 0px;
}

.sidebar .navLink.active {
  background-color: var(--primary-background-color);
}

.sidebar .nav-links li i {
  height: 50px;
  min-width: 40px;
  text-align: center;
  line-height: 50px;
  color: var(--green-color);
  font-size: 24px;
  cursor: pointer;
  transition: all 0.3s ease;
}
.sidebar.close .nav-links li i {
  height: 40px;
  min-width: 40px;
  line-height: 40px;
}

.subMenu-caret-wrapper {
  /* width: 40px;
   */
   width: 63px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.sidebar.close .subMenu-caret-wrapper {
  display: none;
}

.sidebar .nav-links li.showMenu i.arrow {
  transform: rotate(-180deg);
}

.navLink.showMenu .icon-link .subMenu-caret-wrapper > .subMenu-toggler-btn {
  transform: rotate(-180deg);
}

.sidebar.close .nav-links i.arrow,
.sidebar.close .nav-links .subMenu-toggler-btn {
  display: none;
}

.sidebar .nav-links li a {
  display: flex;
  align-items: center;
  text-decoration: none;
  width: 100%;
  height: 50px;
}
.sidebar .nav-links .sub-menu li a {
  height: auto;
}

.sidebar.close .nav-links li a {
  flex-direction: column;
  height: auto;
  justify-content: center;
  gap: 3px;
}

.sidebar .nav-links li a .link_name {
  font-size: 16px;
  font-weight: 400;
  font-family: var(--primary-font-montserrat);
  color: var(--primary-font-color);
  transition: all 0.3s ease;
}

.sidebar.close .nav-links li a .link_name {
  font-size: 12px;
  opacity: 1;
}

.sidebar .nav-links li .sub-menu {
  padding: 0px 0px 0px 60px;
  display: none;
}

.sidebar.close .nav-links li .sub-menu {
  background-color: var(--primary-background-color);
}

.sidebar .nav-links li.showMenu .sub-menu {
  display: block;
}

.sidebar .nav-links li .sub-menu a {
  color: var(--primary-font-color);
  font-size: 15px;
  font-family: var(--primary-font-montserrat);
  padding: 8px 0;
  white-space: nowrap;
  opacity: 0.7;
  transition: all 0.3s ease;
}

.sidebar .nav-links li .sub-menu a:hover {
  opacity: 1;
}

.sidebar.close .nav-links li .sub-menu {
  position: absolute;
  left: calc(100% + 1px);
  top: -10px;
  margin-top: 0;
  padding: 10px 20px;
  border-radius: 0 6px 6px 0;
  opacity: 0;
  display: block;
  pointer-events: none;
  transition: 0s;
}

.sidebar.close .nav-links li .sub-menu li a {
  justify-self: start;
  height: auto;
}

.sidebar.close .nav-links li:hover .sub-menu {
  top: 0;
  opacity: 1;
  pointer-events: auto;
  transition: all 0.3s ease;
}

.sidebar.close .nav-links li .sub-menu .link_name {
  font-size: 15px;
  opacity: 1;
  display: block;
  padding: 5px 0px;
}

.link_name.active {
  font-weight: 600 !important;
  opacity: 1 !important;
}

.sidebar .nav-links li .sub-menu.blank {
  pointer-events: auto;
  padding: 3px 20px 6px 16px;
  opacity: 0;
  pointer-events: none;
}

.sidebar .nav-links li:hover .sub-menu.blank {
  top: 50%;
  transform: translateY(-50%);
}
