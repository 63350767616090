.navbar {
  position: fixed;
  background-color: var(--primary-background-color);
  width: 100%;
  height: 75px;
  display: flex;
  justify-content: space-between;
  padding: 0px 40px;
  z-index: 5;
}

.subNav {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.mainLogo {
  position: relative;
  top: 25px;
  transform: scale(1.5);
}

.icon {
  cursor: pointer;
}
