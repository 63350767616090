.logo {
  width: 300px;
  object-fit: cover;
}

.status {
  font-size: 14px;
  padding: 6px 12px;
  border-radius: 8px;
  letter-spacing: 0.5px;
  font-weight: 500;
  user-select: none;
  margin-right: 5px;
}

.statusGreen {
  background-color: #6ff26f; /* Light green for paid status */
  color: #fff;
}

.statusYellow {
  background-color: #f6f680; /* Light yellow for pending */
}

.statusRed {
  background-color: #e73333; /* Red for overdue */
  color: #fff;
}

.invoiceTable {
  width: 100%;
  margin-top: 15px;
  border-collapse: collapse; /* For clean cell joining */
}

.invoiceTable th,
.invoiceTable td {
  font-family: var(--secondary-font-outfit);
  color: var(--primary-font-color);
  padding: 12px 15px;
  text-align: left; /* Align text to the left */
}

.invoiceTable th {
  background-color: #f8f9fa; /* Light gray for header */
  color: #343a40; /* Dark text for contrast */
  font-weight: bold;
}

.invoiceTable td {
  vertical-align: middle;
}

.invoiceTable textarea {
  width: 100%;
  height: 60px; /* Height for better visibility */
  padding: 8px;
  resize: none;
  font-family: inherit;
  font-size: 14px;
}

.invoiceTable td span {
  display: block; /* Block display for better alignment */
  max-width: 499px; /* Desired max width */
  overflow-wrap: break-word; /* Word breaking */
  line-height: 1.5; /* Improved readability */
}

.heading {
  font-weight: 600 !important;
  font-family: var(--secondary-font-outfit);
  color: var(--primary-font-color);
}

.table-clear {
  margin-top: 15px;
  background-color: #f9f9f9; /* Light background for clarity */
  border-radius: 5px;
}

.table-clear td {
  padding: 12px;
}

.table-clear .heading {
  font-weight: bold;
}

.inputBox {
  font-family: var(--secondary-font-outfit);
  font-size: 15px;
  line-height: 1.25;
  padding: 10px; /* Increased padding for input */
  border-radius: 5px;
  color: var(--primary-font-color);
  border: 1px solid transparent; /* Keep border but invisible */
  transition: border-color 0.3s;
}

.inputBox:focus {
  border-color: #007bff; /* Change border color on focus */
  outline: none; /* Remove default outline */
}

.infoText {
  margin: 0;
  position: absolute;
  right: 9px;
  top: 9px;
  font-family: var(--secondary-font-outfit);
  font-size: 15px;
  color: var(--primary-font-color);
  user-select: none;
}

/* Flexbox adjustments for consistent spacing */
.d-flex {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.gap-2 {
  gap: 0.5rem; /* Gap for spacing */
}

/* Card styles */
.card {
  border-radius: 8px; /* Rounded corners */
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  padding: 20px;
  margin-top: 15px;
}

.card-body {
  padding: 15px;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .invoiceTable td {
    font-size: 14px; /* Smaller text for mobile */
  }

  .inputBox {
    font-size: 14px; /* Adjust input size */
  }

  .status {
    font-size: 12px; /* Smaller status text */
  }
}
.invoiceTable input,
.invoiceTable textarea {
  /* width: 100%; */
  height: 50px; /* Height for better visibility */
  padding: 8px;
  resize: none;
  font-family: inherit;
  font-size: 14px;
  border-radius: 5px;
  border: 1px solid #ced4da;
   /* Default border color */
  transition: border-color 0.3s, color 0.3s; /* Smooth transition for border color */
  
}

/* Change color and border on focus */
.invoiceTable input:focus,
.invoiceTable textarea:focus {
 
  border-color: #f3f3f3; /* Change to a soft pink color on focus */
  outline: none; /* Remove default outline */
}

